(function ($) {
  let popupClass = 'popup-open';
  var iframe = document.querySelector('.sunrype-video__iframe');
  var iframClone = iframe.cloneNode(true);
  var popup = document.querySelector('.sunrype-video__popup');
  iframe.remove();
  $(document).on('click', '.sunrype-video__toggle-button', function () {
    iframe.remove();
    $('.sunrype-video__popup').toggleClass(popupClass);
    $('body').toggleClass(popupClass);
    popup.appendChild(iframClone);
  });
})(jQuery);
